import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-47ce8942"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "navigation-wrap"
};
var _hoisted_2 = {
  key: 0,
  "class": "text"
};
var _hoisted_3 = {
  key: 1,
  "class": "text"
};
var _hoisted_4 = {
  key: 2,
  "class": "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["CesiumMap"], _mergeProps({
    style: {
      "width": "100%",
      "height": "100%"
    },
    ref: "bigemapRef"
  }, _ctx.$attrs, {
    navigation: $setup.props.navigation,
    options: {
      selectionIndicator: true
    }
  }), {
    nav: _withCtx(function () {
      return [$setup.JlinkMqtt2.getAircraftOnline($setup.selectedGateway) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$setup.labelInfo ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.labelInfo.droneName), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.labelInfo ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.labelInfo.elevation), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.labelInfo ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.labelInfo.homeDistance), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)];
    }),
    scrim: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "scrim", {}, undefined, true)];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["navigation"]);
}