import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f4705bbe"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "double-screen-monitor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["AssignmentsMap"], {
    style: {
      "flex": "1",
      "height": "100%"
    },
    navigation: ""
  }), _createVNode($setup["ScreenPlayerItem"], {
    style: {
      "flex": "1",
      "height": "100%"
    },
    modelValue: $setup.selectGateway,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.selectGateway = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"])]);
}