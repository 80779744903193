import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-65244e4c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "double-screen-monitor"
};
var _hoisted_2 = {
  "class": "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode($setup["ScreenPlayerItem"], {
    control: false,
    "model-value": $setup.groups[0],
    "class": "item"
  }, null, 8 /* PROPS */, ["model-value"]), _createVNode($setup["ScreenPlayerItem"], {
    control: false,
    "model-value": $setup.groups[1],
    "class": "item"
  }, null, 8 /* PROPS */, ["model-value"]), _createVNode($setup["ScreenPlayerItem"], {
    control: false,
    "model-value": $setup.groups[2],
    "class": "item"
  }, null, 8 /* PROPS */, ["model-value"]), _createVNode($setup["ScreenPlayerItem"], {
    control: false,
    "model-value": $setup.groups[3],
    "class": "item"
  }, null, 8 /* PROPS */, ["model-value"])])]);
}