import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1a65b076"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "top"
};
var _hoisted_2 = {
  "class": "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": "aircraft-control",
    onClick: $setup.handleSelect
  }, [_createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepAspectView"], {
    aspect: "1:1",
    style: {
      "width": "20%"
    }
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepDroneImage"], {
        drone: $props.drone
      }, null, 8 /* PROPS */, ["drone"]), _createCommentVNode("        <DeepImage class=\"deep-image\" style=\"width: 100%;height: 100%\" fit=\"cover\""), _createCommentVNode("                   src=\"https://senen-public.oss-cn-hangzhou.aliyuncs.com/ge-web-icon/M30T-feiji20230602160139.png\"/>")];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_2, _toDisplayString($props.drone.aircraftName) + "(临时飞机)", 1 /* TEXT */)])]);
}