import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0da2a190"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "left"
};
var _hoisted_2 = {
  "class": "list",
  ref: "listContainer"
};
var _hoisted_3 = {
  "class": "right"
};
var _hoisted_4 = {
  "class": "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock($setup["PageHorizontalLayout"], null, {
    layout0: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        "class": "header",
        onClick: $setup.createAssignments
      }, [_createTextVNode(" 创建任务 "), _createVNode($setup["DeepSvgIcon"], {
        name: "svg-add-circle",
        style: {
          "color": "#595959",
          "margin-left": ".5rem"
        }
      })]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.assignmentsList, function (it) {
        return _openBlock(), _createBlock($setup["AssignmentsItem"], {
          assignments: it,
          key: it.assignmentsId,
          executeJobDrones: $setup.droneExecuteJobTypeSaveValues[it.assignmentsId]
        }, null, 8 /* PROPS */, ["assignments", "executeJobDrones"]);
      }), 128 /* KEYED_FRAGMENT */))], 512 /* NEED_PATCH */), _createElementVNode("div", {
        "class": "bottom",
        onClick: $setup.openHistory
      }, " 历史任务")])];
    }),
    layout1: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createVNode($setup["CesiumMap"], {
        "class": "map",
        navigation: "",
        ref: "bigemapRef"
      }, {
        scrim: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_tooltip, {
            placement: "left",
            content: "指点飞行"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "emergency-plan",
                "class": "location",
                onClick: $setup.toEmergencyTasks
              })];
            }),
            _: 1 /* STABLE */
          })])];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)])];
    }),
    _: 1 /* STABLE */
  });
}