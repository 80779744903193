import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-21ad91a6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "hot"
};
var _hoisted_2 = {
  "class": "deep-cell-header"
};
var _hoisted_3 = {
  "class": "deep-cell-bd"
};
var _hoisted_4 = {
  "class": "deep-title"
};
var _hoisted_5 = {
  "class": "deep-cell-ft"
};
var _hoisted_6 = {
  "class": "deep-cell-desc"
};
var _hoisted_7 = {
  key: 0,
  "class": "deep-cell-desc2"
};
var _hoisted_8 = {
  "class": "deep-cell-bt"
};
var _hoisted_9 = {
  "class": "founder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock($setup["DeepCard"], _mergeProps({
    shadow: "hover",
    "class": "assignments-item"
  }, _ctx.$attrs, {
    onClick: $setup.openAssignments
  }), {
    "default": _withCtx(function () {
      return [$props.assignments.onlineDeviceNum ? (_openBlock(), _createElementBlock("span", _hoisted_1)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.assignments.assignmentsName), 1 /* TEXT */), _createVNode(_component_el_dropdown, {
        disabled: !$setup.JlinkType.assignments.isAssignmentsAdmin($props.assignments)
      }, {
        dropdown: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_menu, null, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_dropdown_item, {
                onClick: $setup.editAssignments
              }, {
                "default": _withCtx(function () {
                  return [_createTextVNode("编辑任务")];
                }),
                _: 1 /* STABLE */
              }), _createVNode(_component_el_dropdown_item, {
                onClick: $setup.deleteAssignments
              }, {
                "default": _withCtx(function () {
                  return [_createTextVNode("删除任务")];
                }),
                _: 1 /* STABLE */
              })];
            }),
            _: 1 /* STABLE */
          })];
        }),
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepSvgIcon"], {
            "class": "icon",
            name: "more",
            onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString($setup.JlinkTransform.assignments.toType($props.assignments)), 1 /* TEXT */), $setup.executeJobTypeDesc ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.executeJobTypeDesc), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, "创建人：" + _toDisplayString($props.assignments.userName), 1 /* TEXT */), _createElementVNode("div", null, "更新时间：" + _toDisplayString($setup.JlinkUtils.date.parseTime($props.assignments.updateTime, '{y}-{m}-{d}')), 1 /* TEXT */)])])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */);
}