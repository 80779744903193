import { createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9ec9d53e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-map"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["AssignmentsMap"], {
    "class": "map",
    ref: "mapRef"
  }, {
    scrim: _withCtx(function () {
      return [_createVNode($setup["AssignmentsMapIcon"], {
        onChangeMessage: $setup.changeMessage
      }), _createVNode($setup["AssignmentsPanelGroupMapMessage"], {
        "class": _normalizeClass(["assignments-message", $setup.messageStyle ? '' : 'assignments-message-hide'])
      }, null, 8 /* PROPS */, ["class"]), _createVNode($setup["AssignmentsPanelGroupMapPlayerList"], {
        modelValue: $setup.show,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.show = $event;
        }),
        assignmentsOpen: $setup.assignmentsOpen
      }, null, 8 /* PROPS */, ["modelValue", "assignmentsOpen"])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)]);
}