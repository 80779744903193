import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a93dde8e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "six-screen-monitor"
};
var _hoisted_2 = {
  "class": "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    "class": "top",
    style: _normalizeStyle({
      width: '100%',
      height: $setup.styles.top1.height
    })
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      width: $setup.styles.top1.width
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.styles.top1.list, function (item, index) {
    return _openBlock(), _createBlock($setup["ScreenPlayerItem"], {
      style: {
        "width": "100%",
        "height": "100%"
      },
      key: index,
      modelValue: $setup.currentDrone,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
        return $setup.currentDrone = $event;
      }),
      control: false
    }, null, 8 /* PROPS */, ["modelValue"]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */), _createElementVNode("div", {
    style: _normalizeStyle([{
      "display": "flex",
      "flex-direction": "column"
    }, {
      width: $setup.styles.top2.width,
      height: '100%'
    }])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.styles.top2.list, function (item, index) {
    return _openBlock(), _createBlock($setup["ScreenPlayerItem2"], {
      "class": "item",
      key: item.droneId,
      drone: item,
      "current-drone": $setup.styles.top1.list[0],
      modelValue: $setup.currentDrone,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
        return $setup.currentDrone = $event;
      }),
      style: _normalizeStyle({
        marginLeft: '.25rem',
        marginTop: index === 0 ? '0' : '.25rem',
        width: '100%',
        height: 100 / $setup.styles.top2.list.length + '%'
      })
    }, null, 8 /* PROPS */, ["drone", "current-drone", "modelValue", "style"]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)], 4 /* STYLE */), _createElementVNode("div", {
    style: _normalizeStyle([{
      "display": "flex",
      "flex-direction": "row"
    }, {
      width: '100%',
      height: $setup.styles.bottom.height
    }]),
    "class": "bottom"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.styles.bottom.list, function (item, index) {
    return _openBlock(), _createBlock($setup["ScreenPlayerItem2"], {
      key: item.droneId,
      drone: item,
      "current-drone": $setup.styles.top1.list[0],
      modelValue: $setup.currentDrone,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
        return $setup.currentDrone = $event;
      }),
      style: _normalizeStyle({
        marginLeft: index === 0 ? '0' : '.25rem',
        width: $setup.styles.bottom.width,
        height: '100%'
      })
    }, null, 8 /* PROPS */, ["drone", "current-drone", "modelValue", "style"]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)])]);
}